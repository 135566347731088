import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";
import styles from "../../styles/modules/Design/LoadingOverlay.module.scss";

const LoadingOverlay = () => {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.centerContainer}>
        <img style={{ width: "200px" }} src="/img/logos/library_logo_lightmode.png" alt="Fiveable Logo" />
        <PropagateLoader
          css={css`
            margin: 0 auto;
            left: 45%;
          `}
          size={15}
          color="#01A0B6"
        />
      </div>
    </div>
  );
};

export default LoadingOverlay;
