import { GetStaticProps } from "next";
import { Logger } from "@/utils/logger";
import { wrapper } from "@/store";
import { setTableOfContentsData, TOCQueryOption } from "@/utils/setTableOfContentsData";
import AuthPage from "@/components/Auth/AuthPage";

const LOGID = "hours:frontend:pages:library:subject-browse";
const log = Logger(LOGID);

export const getStaticProps: GetStaticProps = wrapper.getStaticProps((store) => async () => {
  try {
    await setTableOfContentsData({
      store,
      queryOption: TOCQueryOption.ALL_SUBJECTS_ONLY,
    });

    return {
      props: {}, // Add an empty props object
      revalidate: 21600, // update data every 6 hours
    };
  } catch (error: unknown) {
    log.error("[GET STATIC PROPS ERROR - /login ]: ", error);

    return {
      props: {},
    };
  }
});

export default function LoginPage(): JSX.Element {
  return (
    <AuthPage
      title="Login | Fiveable"
      description="Welcome back! Log into your account to start studying for AP, ACT, SAT, and honors exams!"
      isLogin
    />
  );
}
