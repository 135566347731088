import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { Typography, Stack } from "@mui/material";
import useProfile from "../../utils/useProfile";
import LoadingOverlay from "../Design/LoadingOverlay";
import Layout from "../Layouts/Layout";
import LoginSignupPanel from "./LoginSignupPanel";

interface AuthPageProps {
  description?: string;
  isLogin?: boolean;
  title?: string;
}

export default function AuthPage({ description, isLogin, title }: AuthPageProps): JSX.Element {
  const router = useRouter();
  const { isLoggedIn, isProfileLoading, profile } = useProfile();

  useEffect(() => {
    if (isLoggedIn) {
      router.push("/my-stuff");
    }
  }, [router, isLoggedIn]);

  if (isProfileLoading || profile) {
    return <LoadingOverlay />;
  }

  return (
    <Layout
      description={description}
      title={title}
      navDefaultState={false}
      hideNav
      pageStyles={{ width: "100%" }}>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: 2, md: 4 },
          textAlign: "center",
          width: "100%",
        }}>
        <Typography variant="h1">Got a test tomorrow?</Typography>
        <Typography variant="h5">
          Sign up to get skimmable study guides to quickly get ready for your next test.
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={{ xs: 0, md: 4 }}
          sx={(theme) => ({
            backgroundColor: "primary.focusVisible",
            py: 4,
            px: { xs: 2, md: 4 },
            height: "100%",
            width: "100%",
            borderRadius: theme.borderRadii.MEDIUM,
          })}>
          <Stack sx={{ width: { xs: "80%", md: "450px" }, boxShadow: "none", background: "transparent" }}>
            <LoginSignupPanel isLogin={isLogin} />
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
}
